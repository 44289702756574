<template>
  <div>
    <div class="pd-x-3 themeVote">
      <header class="pd-t-7">
        <el-row class="border-bottom" type="flex">
          <el-col :span="12" class="is-flex ai-center">
            <router-link to="/">
              <img style="width: 88px" src="@/assets/vote-logo.png" alt="" />
            </router-link>
          </el-col>
          <el-col :span="12" class="text-right">
            <el-row type="flex" justify="end">
              <div class="is-flex ai-center mg-r-3" v-if="room != null">
                <span @click="dialogVisible = true" class="cr-pointer">
                  <v-qr
                    :text="url + 'join-room/' + room.code"
                    qrcodeColor="#000000ff"
                    backgroundColor="#ffffffff"
                    :size="60"
                    :ratio="1"
                    :margin="8"
                    level="M"
                  />
                </span>
                <div class="text-left mg-l-7">
                  <h4 class="mg-y-7">
                    <span class="text-grey">ชื่อห้อง:</span> {{ room.name }}
                  </h4>
                  <h4 class="mg-y-7">
                    <span class="text-grey">Room ID:</span> {{ room.code }}
                  </h4>
                </div>
                <el-dropdown
                  v-if="user != null && step != null"
                  trigger="click"
                  class="mg-l-3"
                >
                  <span class="cr-pointer flex-display ai-center">
                    <i class="fas fa-ellipsis-v fa-lg"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="pd-y-7">
                    <div>
                      <el-button type="danger" @click="cancelRoom"
                        >ยกเลิกห้อง</el-button
                      >
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <el-dropdown v-if="user != null" trigger="click">
                <span class="cr-pointer flex-display ai-center">
                  <div class="box-image mg-y-6">
                    <avatar
                      :username="user.name"
                      :rounded="false"
                      :src="
                        `${
                          user.profileImg != 'undefined' && user.profileImg
                            ? imgUrl + user.profileImg
                            : ''
                        }`
                      "
                      :size="40"
                    >
                    </avatar>
                  </div>
                </span>
                <el-dropdown-menu slot="dropdown" style="width: 300px">
                  <el-row>
                    <el-col
                      :span="24"
                      class="is-flex flex-fill js-center pd-b-6"
                    >
                      <div>
                        <div class="flex-display js-center pd-l-4 pd-r-7">
                          <div class="box-image">
                            <avatar
                              :username="user.name"
                              :rounded="false"
                              :src="
                                `${
                                  user.profileImg != 'undefined' &&
                                  user.profileImg
                                    ? imgUrl + user.profileImg
                                    : ''
                                }`
                              "
                              :size="80"
                            >
                            </avatar>
                          </div>
                        </div>
                      </div>
                      <div class="pd-l-6">
                        <h4 class="mg-b-7 mg-t-0">
                          {{ user.name }} {{ user.surname }}
                        </h4>
                        <p class="text-grey mg-y-7">{{ user.email }}</p>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <div class="pd-4 pd-b-6">
                        <router-link to="/admin-edit-profile" class="text-dark">
                          แก้ไขข้อมูลส่วนตัว
                        </router-link>
                      </div>
                      <div class="pd-x-4 pd-b-4">
                        <router-link to="/change-password" class="text-dark">
                          แก้ไขรหัสผ่าน
                        </router-link>
                      </div>
                      <div
                        class="pd-t-4 pd-x-4 border-top"
                        v-if="user.role == 50 || user.role == 60"
                      >
                        <router-link
                          :to="user.role == 50 ? '/log-system' : '/dashboard'"
                          class="text-grey"
                        >
                          เมนูผู้ดูแล
                        </router-link>
                      </div>
                      <div
                        @click="signout()"
                        class="pd-4 pd-y-6 text-grey cr-pointer"
                      >
                        ออกจากระบบ
                      </div>
                    </el-col>
                  </el-row>
                </el-dropdown-menu>
              </el-dropdown>
            </el-row>
          </el-col>
        </el-row>
      </header>
      <div class="pd-y-7">
        <slot />
      </div>

      <el-dialog
        :visible.sync="dialogVisible"
        width="40%"
        top="5vh"
        class="text-center"
      >
        <div v-if="room != null">
          <h3 class="mg-y-0">เข้าร่วม</h3>
          <h2 class="mg-y-6 color-primary">
            <span>{{ url + "join-room/" + room.code }}</span>
          </h2>
          <el-divider><h3 class="mg-y-0">หรือ</h3></el-divider>
          <span>
            <div class="is-flex ai-center js-center">
              <v-qr
                :text="url + 'join-room/' + room.code"
                qrcodeColor="#000000ff"
                backgroundColor="#ffffffff"
                :size="400"
                :ratio="1"
                level="M"
              />
            </div>
          </span>
        </div>
      </el-dialog>
    </div>
    <Cookie :pattern="2" />
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import { HTTP } from "@/service/axios";
import Cookie from "@/components/auth/Cookie";

export default {
  components: {
    Avatar,
    Cookie,
  },
  computed: {
    room() {
      return this.$store.state.room;
    },
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      dialogVisible: false,
      url: process.env.VUE_APP_URL,
      imgUrl: process.env.VUE_APP_IMAGE_URL,
    };
  },
  methods: {
    cancelRoom() {
      this.$confirm(
        "คุณแน่ใจว่าจะออกจากห้อง เมื่อกดออกจากห้องแล้วคุณจะไม่สามารถกลับมาที่ห้องนี้ได้อีก?",
        "Warning",
        {
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          type: "warning",
        }
      )
        .then(() => {
          let obj = {
            roomId: this.room.roomId,
            qId: this.step.qId,
            attId: this.step.attId,
            total: false,
            state: "end",
          };
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post(`state`, obj).then((res) => {
            if (res.data.success) {
              HTTP.put(`end/${this.room.roomId}`)
                .then((result) => {
                  if (result.data.success) {
                    this.setState(obj);
                    this.$message({
                      type: "success",
                      message: "จบการทำข้อสอบ",
                    });

                    this.$router.push("/create-room").catch((err) => {});
                  }
                })
                .catch((err) => {
                  console.log(`end/${this.room.roomId}`, err);
                });
            }
          });
        })
        .catch(() => {});
    },
    async signout() {
      this.$store.commit("SIGNOUT");
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>
